
















import { State } from 'vuex-class';
import { Component } from 'vue-property-decorator';
import UserDto from '@/app/user/dto/user.dto';
import ApartmentDto from '@/app/apartments/dto/apartment.dto';
import HandlesErrorMixin from '@/app/core/mixins/handles-error.mixin';
import ConfirmationDialog from '@/app/core/components/confirmation-dialog.vue';
import ReservationDto from '../dto/reservation.dto';
import ListReservationsDto from '../dto/list-reservations.dto';
import reservationsService, { cancelReservation } from '../services/reservations.service';
import ReservationsDataTable from '../components/reservations-data-table.vue';

@Component({
  metaInfo(this: MyReservations) {
    return { title: this.$t('pageTitle.myReservations').toString() };
  },
  components: {
    ReservationsDataTable,
  },
})
export default class MyReservations extends HandlesErrorMixin {
  @State((state) => state.user)
  private user!: UserDto;

  private loading = true;
  private reservations: ReservationDto[] = [];
  private totalReservations = 0;
  private filters: ListReservationsDto = {
    q: '',
    page: 1,
    itemsPerPage: 10,
  };

  onSearch(q: string) {
    this.filters.q = q;

    this.loadReservations();
  }

  async onCancel(reservation: ReservationDto) {
    const apartment = (reservation.apartment as ApartmentDto).name;
    const title = this.$t('title.cancelReservation');
    const message = this.$t('message.confirmCancelReservation', { apartment });
    const confirm = await this.$dialog.open(ConfirmationDialog, { title, message });

    if (confirm) {
      this.loading = true;
      try {
        await cancelReservation(reservation.id as string);
        this.loadReservations();
        this.$notify.success(this.$t('success.reservationCancelled').toString());
      } catch (err) {
        this.handleError(err);
      } finally {
        this.loading = false;
      }
    }
  }

  async onDelete(reservation: ReservationDto) {
    const apartment = (reservation?.apartment as ApartmentDto).name;
    const title = this.$t('title.deleteReservation');
    const message = this.$t('message.confirmDeleteReservation', { apartment });
    const confirm = await this.$dialog.open(ConfirmationDialog, { title, message });

    if (confirm) {
      try {
        await reservationsService.deleteReservation(reservation.id as string);
        this.loadReservations();
      } catch (err) {
        this.handleError(err);
      }
    }
  }

  onPageChange(page: number) {
    this.filters.page = page;

    this.loadReservations();
  }

  onUpdateItemsPerPage(itemsPerPage: number) {
    this.filters.page = 1;
    this.filters.itemsPerPage = itemsPerPage;

    this.loadReservations();
  }

  async loadReservations() {
    this.loading = true;

    try {
      const response = await reservationsService.listReservations(this.filters);

      this.reservations = response.data.items;
      this.totalReservations = response.data.totalItems;
    } catch (error) {
      this.handleError(error);
    } finally {
      this.loading = false;
    }
  }

  mounted() {
    this.filters.ownerId = this.user.id;
    this.loadReservations();
  }
}
